<template>
  <div>
    <!-- 解决方案顶部banner -->
    <application-banner></application-banner>
    <!-- 解决方案顶部介绍图 -->
    <application-topimg></application-topimg>
    <!-- 解决方案二级路由出口 -->
    <router-view></router-view>
  </div>
</template>

<script>
import applicationBanner from "./components/application-banner.vue";
import ApplicationTopimg from "./components/application-topimg.vue";
export default {
  components: { applicationBanner, ApplicationTopimg },
  name: "Application",
};
</script>

<style lang="less" scoped>
</style>